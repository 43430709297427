<template>
  <b-card-code title="Visualisation des PDV et Distributeurs">
    <b-row>
      <b-col
        v-if="regions.length"
        md="4"
      >
        <b-form-group
          label="Regions"
          label-for="regions"
        >
          <v-select
            v-model="filter.region"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="nom"
            :options="regions"
            @input="onRegionChange"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="zones.length"
        md="4"
      >
        <b-form-group
          label="Zones"
          label-for="zones"
        >
          <v-select
            v-model="filter.zone"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="nom"
            :options="zones"
            @input="onZoneChange"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="commercials.length"
        md="4"
      >
        <b-form-group
          label="Commercials"
          label-for="commercials"
        >
          <v-select
            v-model="filter.commercial"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="full_name"
            :options="commercials"
          />
        </b-form-group>
      </b-col>

      <b-col md="12">
        <div style="text-align: center">
          <b-button
            id="toggle-btn"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn_export ml-2"
            @click="getData"
          >
            <feather-icon icon="SearchIcon" />
            Filtrer
          </b-button>
        </div>

      </b-col>
    </b-row>

    <div>
      <b-badge
        v-if="loadingPDV"
        variant="light-success"
      >
        Chargement des points de vente...
      </b-badge>
      <br><br>
      <b-badge
        v-if="loadingDistributeur"
        variant="light-success"
      >
        Chargement des distributeurs...
      </b-badge>
    </div>
    <b-row>
      <b-col
        v-if="!loadingPDV"
        md="3"
      >
        <b-form-checkbox
          v-model="PDVsIsChecked"
          name="my-checkbox"
          @change="handleCheckboxChange"
        >
          PDVs
        </b-form-checkbox>
      </b-col>
      <b-col
        v-if="!loadingDistributeur"
        md="3"
      >
        <b-form-checkbox
          v-model="distributeursIsChecked"
          name="my-checkbox"
          @change="handleCheckboxChange"
        >
          Distributeurs
        </b-form-checkbox>
      </b-col>
    </b-row>
    <hr>
    <!-- map -->
    <l-map
      :key="cardKey"
      style="height: 700px"
      :zoom="zoom"
      :center="center"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <div v-if="markers.length">
        <v-marker-cluster :options="clusterOptions">
          <l-marker
            v-for="item in markers"
            :key="item.id"
            :lat-lng="item.position"
            :icon="item.icon"
          >
            <l-popup :content="item.type+': '+item.nom" />
          </l-marker>
        </v-marker-cluster>
      </div>

    </l-map>
    <template #code>
      {{ codeLayerGroup }}
    </template>
    <hr>
    <b-row>
      <b-col md="12">
        <b-tabs
          pills
        >
          <b-tab active>
            <template #title>
              <feather-icon
                icon="ListIcon"
                size="18"
              />
              Liste des points de vente [ {{ pdvs.length }} ]
            </template>
            <hr>
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Recherche
                  </label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Recherche"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
              <!-- primary -->
              <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Exporter"
                variant="primary"
                class="ml-2 btn_export"
              >
                <b-dropdown-item @click="exportPDVsCSVS">
                  CSV
                </b-dropdown-item>
                <b-dropdown-item @click="exportPDVsExcel">
                  MS-Excel
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <vue-good-table
              id="list"
              ref="myTable"
              :columns="columnsPdvs"
              :rows="pdvs"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: Status -->
                <span v-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <b-button-group>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click="showMarker(props.row, 'pdv')"
                    >
                      Voir
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      :to="{ name: 'points-de-vente-details', params: { id: props.row.id } }"
                    >
                      Details
                    </b-button>
                  </b-button-group>
                </span>
                <span v-else-if="props.column.field === 'nbr_days_since_last_visite'">
                  <b-badge
                    v-if="props.row.nbr_days_since_last_visite"
                    variant="success"
                  >
                    {{ props.row.nbr_days_since_last_visite }} jours
                  </b-badge>
                  <b-badge
                    v-else
                    variant="danger"
                  >
                    Jamais visité
                  </b-badge>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      Affichage de 1 à
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap "> sur {{ props.total }} élements </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
            <hr>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon
                icon="ListIcon"
                size="18"
              />
              Liste des distributeurs [ {{ distributeurs.length }} ]
            </template>
            <hr>
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Recherche
                  </label>
                  <b-form-input
                    v-model="searchTermDistributeurs"
                    placeholder="Recherche"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
              <!-- primary -->
              <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Exporter"
                variant="primary"
                class="ml-2 btn_export"
              >
                <b-dropdown-item @click="exportPDVsCSVS">
                  CSV
                </b-dropdown-item>
                <b-dropdown-item @click="exportPDVsExcel">
                  MS-Excel
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <vue-good-table
              id="list"
              ref="myTable"
              :columns="columnsDistributeurs"
              :rows="distributeurs"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTermDistributeurs }"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: Status -->
                <span v-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <b-button-group>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click="showMarker(props.row, 'distributeur' )"
                    >
                      Voir
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      :to="{ name: 'distributeurs-details', params: { id: props.row.id } }"
                    >
                      Details
                    </b-button>
                  </b-button-group>
                </span>

                <span v-else-if="props.column.field === 'nbr_days_since_last_visite'">
                  <b-badge
                    v-if="props.row.nbr_days_since_last_visite"
                    variant="success"
                  >
                    {{ props.row.nbr_days_since_last_visite }} jours
                  </b-badge>
                  <b-badge
                    v-else
                    variant="danger"
                  >
                    Jamais visité
                  </b-badge>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      Affichage de 1 à
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap "> sur {{ props.total }} élements </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
            <hr>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BFormGroup,
  BFormCheckbox,
  BBadge,
  BFormInput,
  BPagination,
  BDropdownItem,
  BDropdown, BTabs, BTab, BButtonGroup,
} from 'bootstrap-vue'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolyline,
  LLayerGroup,
  LTooltip,
  LPopup,
  LControlZoom,
  LControlAttribution,
  LControlScale,
  LControlLayers,
} from 'vue2-leaflet'
import { latLngBounds, Icon, icon } from 'leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import './assets/MarkerCluster.css'
import './assets/MarkexrCluster.Default.css'
import { API_URL } from '@/helpers/global-scops'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import LeafletPopup from './LeafletPopup.vue'
// import LeafletGeoJSON from './LeafletGeoJSON.vue'
// eslint-disable-next-line no-unused-vars
import * as XLSX from 'xlsx'
import { generateConcatenatedWord } from '@/helpers/methodes'
import VueGoodTable from 'vue-good-table/src/components/Table.vue'
import LeafletBasic from './LeafletBasic.vue'
import { codeLayerGroup } from './code'// import LeafletMarkerCirclePolygon from './LeafletMarkerCirclePolygon.vue'
// import LeafletCustomIcon from './LeafletCustomIcon.vue'

const tileProviders = [
  {
    name: 'OpenStreetMap',
    visible: true,
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
  {
    name: 'OpenTopoMap',
    visible: false,
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution:
      'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
  },
]

const markersReseau1 = []
const markersReseau2 = []

const latOuaga = 12.3714
const lngOuaga = -1.5333

// eslint-disable-next-line no-plusplus
for (let i = 0; i < 1000; i++) {
  const lat = latOuaga + (Math.random() - 0.5) / 10 // Ajoute un nombre aléatoire entre -0.05 et 0.05 à la latitude de Ouagadougou
  const lng = lngOuaga + (Math.random() - 0.5) / 10 // Ajoute un nombre aléatoire entre -0.05 et 0.05 à la longitude de Ouagadougou
  markersReseau1.push({
    position: {
      lat,
      lng,
    },
  })
}
// eslint-disable-next-line no-plusplus
for (let i = 0; i < 1000; i++) {
  const lat = latOuaga + (Math.random() - 0.5) / 10 // Ajoute un nombre aléatoire entre -0.05 et 0.05 à la latitude de Ouagadougou
  const lng = lngOuaga + (Math.random() - 0.5) / 10 // Ajoute un nombre aléatoire entre -0.05 et 0.05 à la longitude de Ouagadougou
  markersReseau2.push({
    position: {
      lat,
      lng,
    },
  })
}

export default {
  components: {
    BButtonGroup,
    VueGoodTable,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormInput,
    BBadge,
    BFormCheckbox,
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LLayerGroup,
    LTooltip,
    LPopup,
    LControlZoom,
    LControlAttribution,
    LControlScale,
    LControlLayers,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    BFormGroup,
    BCardCode,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      cardKey: 0,
      searchTerm: '',
      searchTermDistributeurs: '',
      pageLength: 10,
      columnsPdvs: [
        {
          label: 'Nom',
          field: 'nom',
          filterable: true,
        },
        {
          label: 'Gérant',
          field: 'gerant',
          filterable: true,
        },
        {
          label: 'Télephone',
          field: 'contact',
          filterable: true,
        },
        {
          label: 'Secteur',
          field: 'secteur',
          filterable: true,
        },
        {
          label: 'Commercial',
          field: 'commercial',
          filterable: true,
        },
        {
          label: 'Nombre de visites',
          field: 'nbr_visites',
          filterable: true,
          type: 'number',
        },
        {
          label: 'Dernière visite',
          field: 'date_last_visite',
          filterable: true,
        },
        {
          label: 'Nombre de jours',
          field: 'nbr_days_since_last_visite',
          filterable: true,
          type: 'number',
        },
        {
          label: 'Action',
          field: 'action',
          filterable: true,
        },
      ],
      columnsDistributeurs: [
        {
          label: 'Nom',
          field: 'nom',
          filterable: true,
        },
        {
          label: 'Gérant',
          field: 'gerant',
          filterable: true,
        },
        {
          label: 'Télephone',
          field: 'contact',
          filterable: true,
        },
        {
          label: 'Commercial',
          field: 'commercial',
          filterable: true,
        },
        {
          label: 'Nombre de visites',
          field: 'nbr_visites',
          filterable: true,
          type: 'number',
        },
        {
          label: 'Dernière visite',
          field: 'date_last_visite',
          filterable: true,
          type: 'number',
        },
        {
          label: 'Nombre de jours',
          field: 'nbr_days_since_last_visite',
          filterable: true,
        },
        {
          label: 'Action',
          field: 'action',
          filterable: true,
        },
      ],
      regions: [],
      zones: [],
      commercials: [],
      filter: {
        region: '',
        zone: '',
        commercial: '',
      },
      PDVsIsChecked: true,
      distributeursIsChecked: true,
      icon: icon({
        iconUrl: 'markers/user_online.png',
        iconSize: [16, 16],
        iconAnchor: [16, 6],
      }),
      clusterOptions: {
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        disableClusteringAtZoom: 13,
      },
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      codeLayerGroup,
      center: [11.905720, -1.293255],
      opacity: 0.6,
      token: 'your token if using mapbox',
      mapOptions: {
        // zoomControl: false,
        // attributionControl: false,
        // zoomSnap: true,
      },
      zoom: 6,
      minZoom: 1,
      maxZoom: 20,
      zoomPosition: 'topleft',
      attributionPosition: 'bottomright',
      layersPosition: 'topright',
      attributionPrefix: 'Vue2Leaflet',
      imperial: false,
      Positions: ['topleft', 'topright', 'bottomleft', 'bottomright'],
      tileProviders,
      markers: [],
      reseau1: [
        {
          id: 's1',
          markers: markersReseau1,
          polyline: {
            points: markersReseau1,
            visible: true,
          },
          visible: true,
          markersVisible: true,
        },
      ],
      reseau2: [
        {
          id: 's2',
          markers: markersReseau2,
          polyline: {
            points: markersReseau2,
            visible: true,
          },
          visible: true,
          markersVisible: true,
        },
      ],
      bounds: latLngBounds(
        {
          lat: 51.476483373501964,
          lng: -0.14668464136775586,
        },
        {
          lat: 51.52948330894063,
          lng: -0.019140238291583955,
        },
      ),
      reseauxDistribution1: [],
      reseauxDistribution2: [],
      reseauxDistribution3: [],
      reseauxDistribution4: [],
      pdvs: [],
      distributeurs: [],
      loadingPDV: false,
      loadingDistributeur: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getRegions()
  },
  methods: {
    handleCheckboxChange() {
      this.markers = []
      if (this.PDVsIsChecked) {
        // this.markers = this.markers.concat(this.pdvs)
        this.markersFormatter(this.pdvs, 'pdv')
        console.log('La case pdv est cochée !')
        // Effectuez ici les actions à effectuer lorsque la case est cochée
      } else {
        this.markers = this.markers.filter(marker => marker.type !== 'pdv')
        console.log('La case pdv est décochée !')
        // Effectuez ici les actions à effectuer lorsque la case est décochée
      }
      if (this.distributeursIsChecked) {
        // this.markers = this.markers.concat(this.distributeurs)
        this.markersFormatter(this.distributeurs, 'distributeur')
        console.log('La case est cochée !')
        // Effectuez ici les actions à effectuer lorsque la case est cochée
      } else {
        this.markers = this.markers.filter(marker => marker.type !== 'distributeur')
        console.log('La case est décochée !')
        // Effectuez ici les actions à effectuer lorsque la case est décochée
      }
      console.log(this.markers)
    },
    addMarker() {
      const newMarker = {
        position: {
          lat: 50.5505,
          lng: -0.09,
        },
        draggable: true,
        visible: true,
      }
      this.markers.push(newMarker)
    },
    removeMarker(index) {
      this.markers.splice(index, 1)
    },
    showR1() {
      const bounds = latLngBounds(markersReseau1.map(o => o.position))
      this.bounds = bounds
    },
    showR2() {
      const bounds = latLngBounds(markersReseau2.map(o => o.position))
      this.bounds = bounds
    },
    getPDV() {
      this.loadingPDV = true
      let url = `${API_URL}geolocalisations/pdvs-list/`

      const params = new URLSearchParams()
      if (this.filter.region && this.filter.region.id) {
        params.append('region', this.filter.region.id)
      }
      if (this.filter.zone && this.filter.zone.id) {
        params.append('zone', this.filter.zone.id)
      }
      if (this.filter.commercial && this.filter.commercial.uuid) {
        params.append('commercial_id', this.filter.commercial.uuid)
      }

      if (params.toString()) {
        url += `?${params.toString()}`
      }

      this.$http.get(url)
        .then(response => {
          this.loadingPDV = false
          this.pdvs = response.data
          console.log('PDV : ', this.pdvs)
          this.markersFormatter(this.pdvs, 'pdv')
        })
        .catch(error => {
          this.loadingDistributeur = false
          console.log(error)
        })
    },
    markersFormatter(data, type) {
      data.forEach(item => {
        this.markers.push({
          nom: item.nom,
          position: {
            lat: item.latitude,
            lng: item.longitude,
          },
          type,
          geolocalisation: item.latitude && item.longitude,
          icon: icon({
            iconUrl: 'markers/user_online.png',
            iconSize: [16, 16],
            iconAnchor: [16, 6],
          }),
          draggable: false,
          visible: true,
        })
      })
    },
    getDistributeurs() {
      this.loadingDistributeur = true

      let url = `${API_URL}geolocalisations/distributeurs-list/`

      const params = new URLSearchParams()
      if (this.filter.region && this.filter.region.id) {
        params.append('region', this.filter.region.id)
      }
      if (this.filter.zone && this.filter.zone.id) {
        params.append('zone', this.filter.zone.id)
      }
      if (this.filter.commercial && this.filter.commercial.uuid) {
        params.append('commercial_id', this.filter.commercial.uuid)
      }

      if (params.toString()) {
        url += `?${params.toString()}`
      }

      this.$http.get(url)
        .then(response => {
          this.loadingDistributeur = false
          this.distributeurs = response.data
          console.log('Distributeurs : ', this.distributeurs)
          this.markersFormatter(this.distributeurs, 'distributeur')
        })
        .catch(error => {
          this.loadingDistributeur = false
          console.log(error)
        })
    },
    getRegions() {
      this.$http.get(`${API_URL}tiny/regions-list/`)
        // this.markers = []
        .then(response => {
          this.regions = response.data
          // eslint-disable-next-line prefer-destructuring
          this.filter.region = this.regions[0]
          this.getZones()
        })
        .catch(error => {
          console.log(error)
        })
    },
    getZones() {
      this.$http.get(`${API_URL}tiny/zones-list/?region_id=${this.filter.region.id}`)
        .then(response => {
          this.zones = response.data
          // eslint-disable-next-line prefer-destructuring
          this.filter.zone = this.zones[0]
          this.getCommercials()
        })
        .catch(error => {
          console.log(error)
        })
    },
    getCommercials() {
      this.$http.get(`${API_URL}tiny/commercials-list/?zone_id=${this.filter.zone.id}`)
        .then(response => {
          this.commercials = response.data
          // eslint-disable-next-line prefer-destructuring
          // this.filter.commercial = this.commercials[0]
          this.getData()
        })
        .catch(error => {
          this.loadingDistributeur = false
          this.loadingPDV = false
          console.log(error)
        })
    },

    getData() {
      this.markers = []
      this.getPDV()
      this.getDistributeurs()
    },
    onRegionChange() {
      console.log(this.filter.region.id)
      this.getZones()
    },
    onZoneChange() {
      this.getCommercials()
    },
    exportPDVsExcel() {
      const header = this.columnsPdvs.map(column => column.label)
      const data = [header].concat(this.pdvs.map(row => this.columnsPdvs.map(column => row[column.field])))

      // Créer un workbook avec une feuille
      const ws = XLSX.utils.aoa_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Export')

      // Générer le fichier et le télécharger
      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array',
      })
      const blob = new Blob([wbout], { type: 'application/octet-stream' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${generateConcatenatedWord('pdv-geolocalisation')}.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    exportPDVsCSVS() {
      const header = this.columnsPdvs.map(column => column.label)
      const data = this.pdvs.map(row => this.columnsPdvs.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(','))
        .join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${generateConcatenatedWord('pdv-geolocalisation')}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    exportDistributeursExcel() {
      const header = this.columnsDistributeurs.map(column => column.label)
      const data = [header].concat(this.distributeurs.map(row => this.columnsDistributeurs.map(column => row[column.field])))

      // Créer un workbook avec une feuille
      const ws = XLSX.utils.aoa_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Export')

      // Générer le fichier et le télécharger
      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array',
      })
      const blob = new Blob([wbout], { type: 'application/octet-stream' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${generateConcatenatedWord('distributeur-geolocalisation')}.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    exportDistributeursCSVS() {
      const header = this.columnsDistributeurs.map(column => column.label)
      const data = this.distributeurs.map(row => this.columnsDistributeurs.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(','))
        .join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${generateConcatenatedWord('pdv-geolocalisation')}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    showMarker(pdv, type) {
      //   this.loadingPDV = false
      this.markers = []
      this.markers.push({
        nom: pdv.nom,
        position: {
          lat: pdv.latitude,
          lng: pdv.longitude,
        },
        type,
        geolocalisation: pdv.latitude && pdv.longitude,
        icon: icon({
          iconUrl: 'markers/user_online.png',
          iconSize: [16, 16],
          iconAnchor: [16, 6],
        }),
        draggable: false,
        visible: true,
      })
      this.bounds = latLngBounds(
        {
          lat: pdv.latitude,
          lng: pdv.longitude,
        },
      )
      this.center = [
        pdv.latitude,
        pdv.longitude]
      this.cardKey += 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';

</style>
<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>
